import Ready from '@/utils/ready';
import detectElement from '@/plugins/detect-element';
import {CountUp} from "countup.js";

const selector = '[data-ref="countUp"]';

(() => {
    Ready.watch(selector, (element: any) => {
        let hasBeenDetected = 0;
        const counter = new CountUp(element, 3000, {
            separator: '',
            duration: 6,
            prefix: '+',
        });
        new detectElement(element, () => {
            hasBeenDetected += 1;
            if (hasBeenDetected === 1) {
                counter.start();
            }
        });
    })
})();
