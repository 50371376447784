import Dialog from "@/js/dialog";

if (!!(window as any).MSInputMethodContext && !!(document as any).documentMode) {
    const ieDialog = document.querySelector('[data-ref="dialog"][data-id="coldway-ie"]') || null;
    const dialogButton = (ieDialog as any).querySelector('.ieDialogClose');
    // const ieAdvert = localStorage.getItem('coldway-ieDialog') || null;
    const dialog = new Dialog((ieDialog as any));

    if (ieDialog) {
        // if (!JSON.parse(ieAdvert)) {
            dialog.open();
            // localStorage.setItem('coldway-ieDialog', 'true');
        // }
    // } else {
        // localStorage.setItem('coldway-ieDialog', 'true');
        // dialog.open();
    }

    dialogButton.addEventListener('click', (e: any) => {
        dialog.close();
    });
}
