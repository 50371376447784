import {gsap, Power4} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

class Scroller {
    private readonly duration: number = .8;
    private readonly offset: number = 80;

    constructor(duration: any, offset: number) {
        this.duration = duration || this.duration;
        this.offset = offset || this.offset;
    }

    public scrollTo(target: HTMLElement) {
        gsap.to(window, {duration: this.duration, ease: Power4.easeInOut, scrollTo: {y: target, offsetY: this.offset}})
    }

    // public scrollTo() {
    //     const y = (this.el.getBoundingClientRect().top + document.documentElement.scrollTop) - this.offset;
    //     if (this.isIE11) {
    //         window.scrollTo(0, y);
    //     } else {
    //         window.scrollTo({
    //             top: y,
    //             behavior: 'smooth',
    //         });
    //     }
    // }
}

export default Scroller;
