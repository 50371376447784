import Ready from '@/utils/ready';
import Scroller from '@/js/scroller';

const selector = '[data-ref="alert"]';
let scroller = new Scroller(null, 180);

Ready.watch(selector, (element: any) => {
    const closeButton = element.querySelector('[data-close]');
    scroller.scrollTo(element);
   if (closeButton) {
      closeButton.addEventListener('click', (e: any) => {
         closeButton.closest('[data-ref="alert"]').remove();
      })
   }
});
