const selector = document.querySelector('[data-ref="navigationResponsive"]');

class Navigation {
    private el: HTMLElement;
    private toggleButton: HTMLElement | null;
    private closeButton: HTMLElement | null;

    constructor(element: HTMLElement) {
        this.el = element;
        this.toggleButton = document.querySelector('[data-ref="toggleButton"]');
        this.closeButton = (this as any).el.querySelector('[data-ref="toggleClose"]');
        this.addEvents();
    }

    private addEvents() {
        (this as any).toggleButton.addEventListener('click', (e: any) => {
            (this as any).el.classList.add('open');
        });
        (this as any).el.addEventListener('click', (e: any) => {
            if (e.target === this.closeButton || this.isChildren(this.closeButton, e.target)) {
                (this as any).el.classList.remove('open');
            }
        });
    }

    private isChildren(parent: any, children: any) {
        return Array.from(parent.children).includes(children);
    }
}

(() => {
    if (selector) {
        new Navigation((selector as HTMLElement));
    }
})();

