import Ready from '@/utils/ready';
// @ts-ignore
import * as Swiper from "swiper/js/swiper.js";

const selector = '[data-ref="swiper"]';

(() => {
    Ready.watch(selector, (element: any) => {
        const options: any = element.dataset.options ? JSON.parse(element.dataset.options) : null;
        const slider = new (Swiper as any)(element, {
            navigation: {
                nextEl: '.button-next',
                prevEl: '.button-prev',
            },
            loop: false,
            // breakpoints: {
            //     768: {
            //         slidesPerView: 1.3,
            //         spaceBetween: 35,
            //     },
            // },
            ...options,
        });
        if (element.dataset && element.dataset.productGallery === 'true') {
            slider.on('slideChange', () => {
                const currentSlide = (document as any).querySelector(`.swiper-slide[data-slide-index="${slider.activeIndex + 1}"]`);
                const parent = currentSlide.closest('.imageContainer');
                (parent as any).dataset.currentName = currentSlide.dataset.name;
            });
        }
    })
})();
