import Ready from '@/utils/ready';
const selector = '[data-ref="videoThumbnail"]';

class videoThumbnail {
    private el: HTMLElement;
    private thumbnail: HTMLElement | null;
    private playButton: HTMLElement | null;
    private video: HTMLElement | null;

    constructor(element: any) {
        this.el = element;
        this.thumbnail = this.el.querySelector('[data-thumbnail]');
        this.playButton = this.thumbnail ? this.thumbnail.querySelector('[data-button]') : null;
        this.video = this.el ? this.el.querySelector('video') : null;
        (this as any).video.addEventListener('canplaythrough', (e: any) => {
            this.init();
        });
        (this.video as HTMLVideoElement).load();
    }

    private init() {
        this.el.classList.remove('video--loading');
        (this as any).playButton.addEventListener('click', (e: any) => {
            this.el.classList.add('video--play');
            (this as any).video.play();
        });
    }
}

Ready.watch(selector, (element: any) => {
    new videoThumbnail(element);
})
