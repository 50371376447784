export default class detectElement {
    private el: any;
    private callback: Function;

    constructor(element: any = null, callback: Function) {
        this.el = element;
        this.callback = callback;
        this.init();
    }

    init() {
        document.addEventListener('scroll', () => {
            const bounding = this.el.getBoundingClientRect();
            if (
                bounding.top >= 0 &&
                bounding.left >= 0 &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            ) {
                this.callback();
            }
        });
    }
}
