import Vue from "vue";
// import App from "./App.vue";
// import store from "./store";
import "./plugins/element.js";

import '@/js/detect-ie';
import '@/js/assets';
import "@/js/form-validation";
import '@/js/count-up';
import '@/js/swiper';
import '@/js/video-thumbnail';
import '@/js/alert';
import '@/js/scrollTo';
// import '@/js/dialogButton';
import '@/js/navigation';
import '@/js/choice';
import '@/js/videoRate';

Vue.config.productionTip = false;

// new Vue({
//   store,
//   render: h => h(App)
// }).$mount("#app");
