import Ready from '@/utils/ready';

const selector = '[data-ref="video"][data-rate]';

(() => {
    Ready.watch(selector, (element: any) => {
        const rate = element.dataset.rate;
       if (rate) {
           element.playbackRate = Number(rate);
           element.load();
       }
    });
})();
