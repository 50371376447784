class Dialog {
    private el: HTMLElement;
    private isOpen: boolean = false;
    private readonly closeEl: HTMLElement | null;
    private readonly backgroundEl: HTMLElement | null;
    constructor(element: HTMLElement) {
        this.el = element;
        this.closeEl = this.el.querySelector('[data-ref="dialogClose"]');
        this.backgroundEl = this.el.querySelector('[data-ref="dialogBackground"]');
        this.addEvents();
    }

    private addEvents() {
        document.addEventListener('keydown', (e: any) => {
            const key = e.key;
            if (key === 'Escape' && this.isOpen) {
                this.close();
            }
        });

        (this.el as any).addEventListener('click', (e: any) => {
            if (
                e.target.closest('[data-ref="dialogClose"]') === this.closeEl ||
                e.target === this.backgroundEl
            ) {
                this.close();
            }
        });
    }

    public open() {
        this.isOpen = true;
        document.body.classList.add('overflow-y-hidden');
        this.el.classList.add('dialog--open');
    }

    public close() {
        this.isOpen = false;
        document.body.classList.remove('overflow-y-hidden');
        this.el.classList.remove('dialog--open');
    }
}

export default Dialog;
