const pristineConfig = {
    containerBp: {
        sm: '576px',
        md: '768px',
        lg: '992px',
        xl: '1110px',
    },
    screens: {
        sm: '576px',
        md: '768px',
        lg: '992px',
        xl: '1200px',
        'xl2': '1400px',
        'xl3': '1600px',
    },
    gutters: {
        xxl2: '50px',
        base: '15px',
    },
    colors: {
        primary: '#1B1D3A',
        secondary: '#902045',
        tertiary: '#2663B7',
        orange: '#B77426',
        gray: '#F3F3F5',
        'gray-darkest': '#C7C8D0',
        green: '#4DB84D',
        'green-darkest': '#105A25',
        'green-light': '#4db84d80',
        black: '#0F1022',
        transparent: 'transparent'
    },
    spacing: {
        xxl2: '100px',
        xxl: '50px',
        xl: '40px',
        lg: '30px',
        base: '24px',
        md: '24px',
        sm: '15px',
        xs: '10px',
        xxs: '5px',
    },
    unit: {
      lg: '40px',
      sm: '25px',
      xs: '16px',
      xxs: '8px',
    },
    fontSize: {
        xxl: '72px',
        xl: '48px',
        lg: '36px',
        md: '24px',
        sm: '21px',
        base: '16px',
        xs: '14px',
        xxs: '12px',
    },
    letterSpacing: {
        xl: '1.75px',
        lg: '1.5px',
        base: '1px',
        md: '0.25px',
        sm: '0.01em',
    },
    backgroundColor: {},
    backgroundPosition: {},
    backgroundSize: {},
    borderColor: {},
    borderRadius: {},
    borderWidth: {},
    boxShadow: {
        base: '0px 0.5rem 0.5rem 0px rgba(38, 99, 183, 0.6)',
        sm: '0 0.2rem 0.5rem 0 rgba(38, 99, 183, 0.6)',
        secondary: '0 0.1rem 0.6rem rgba(144, 32, 69, 0.6)',
    },
    container: {},
    cursor: {},
    fill: {},
    flex: {},
    flexGrow: {},
    flexShrink: {},
    fontFamily: {},
    fontWeight: {},
    height: {},
    inset: {},
    lineHeight: {
        base: '24px',
    },
    listStyleType: {},
    margin: {},
    maxHeight: {},
    maxWidth: {},
    minHeight: {},
    minWidth: {},
    objectPosition: {},
    opacity: {},
    order: {},
    padding: {},
    stroke: {},
    textColor: {},
    width: {},
    zIndex: {},
};

module.exports = pristineConfig;
