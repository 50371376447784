import Scroller from "@/js/scroller";

(() => {
    (window as any).scroller = new Scroller(null, 150);
    const scroller = new Scroller(null, -50);
    const links = Array.from(document.querySelectorAll('a[href]'));
    const menuLinks = document.querySelectorAll('[data-ref="menuItem"]');
    const hashLinks = links.filter((link: any) => link.getAttribute('href')[0] === '#');
    const removeActive = (activeLink: any) => {
        menuLinks.forEach((menuLink: any) => {
            menuLink.closest('li').classList.remove('active');
        });
        (document as any).querySelectorAll(`[data-ref="menuItem"] > a[href="${activeLink.getAttribute('href')}"]`).forEach((item: any) => {
            item.closest('li').classList.add('active');
        })
    };
    document.addEventListener('click', (e: any) => {
        if (hashLinks.includes(e.target)) {
            e.preventDefault();
            removeActive(e.target);
            // @ts-ignore
            scroller.scrollTo(e.target.getAttribute('href'));
        }
    });
})();
